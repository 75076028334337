/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

import { IP4_REGEX } from '../modules/navigation/navigation.constants';

export const isSafari = () => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

export const replaceUrl = (url: string) => {
  window.history.replaceState(null, '', url);
};

export const getBaseUrl = () => {
  return window.location.protocol + '//' + window.location.host;
};

export const getMainDomain = () => {
  const { hostname } = window.location;
  return hostname.match(IP4_REGEX)
    ? hostname
    : `.${hostname.split('.').slice(-2).join('.')}`;
};

export const getPathAfterHash = () => {
  return window.location.hash.slice(1).trim();
};

export const isAppSelfHosted = () => {
  return window.self === window.top;
};
