/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

import axios from 'axios';

import { getBaseUrl } from '../../domain/browser.domain';
import { getTime } from '../../domain/dates/dates.domain';
import { GlobalConfigDataType } from '../../domain/global-config-data.types';

export const getGlobalConfigData = () => {
  return axios
    .get<GlobalConfigDataType>(
      `${getBaseUrl()}/config/global-config.json?t=${getTime()}`
    )
    .then(({ data }) => data)
    .catch(() => {
      throw Error('Error fetching config data');
    });
};
