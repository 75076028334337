/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

import { CookieSetOptions } from 'universal-cookie';

import { getMainDomain } from '../browser.domain';
import { getCurrentDate } from '../dates/dates.domain';

export const getCookieOptions = (): CookieSetOptions => ({
  ...getBaseOptions(),
  domain: getMainDomain(),
  expires: getExpiration(),
  path: '/',
});

const getBaseOptions = (): Partial<CookieSetOptions> => {
  return process.env.NODE_ENV === 'development'
    ? { sameSite: 'lax', secure: false }
    : { sameSite: 'none', secure: true };
};

const getExpiration = () => {
  const currentDate = getCurrentDate();
  currentDate.setFullYear(currentDate.getFullYear() + 1);
  return currentDate;
};
