/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

import axios from 'axios';

import { ResultResponseType } from '../../domain/result-response.types';
import { SaveVisitRequest } from './tracking.service.types';

export const saveVisit = (request: SaveVisitRequest) => {
  return axios
    .post<ResultResponseType>(`/api/event/visit/`, {
      ...request,
      loginType: request.loginType.toUpperCase(),
    })
    .then(({ data }) => data)
    .catch(() => {
      throw Error('Error saving visit.');
    });
};
