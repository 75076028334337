/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

export const toDate = (dateString?: string) => {
  return dateString ? new Date(dateString) : undefined;
};

export const dateToString = (date = new Date()) => {
  return date.toISOString();
};

export const getTime = (date = new Date()) => {
  return date.getTime();
};

export const getCurrentDate = () => {
  return new Date();
};

export const getCurrentYear = () => {
  return new Date().getFullYear();
};
