/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

import i18next from 'i18next';

export const keyHasValue = (key: string) => {
  return i18next.exists(key) && i18next.t(key) !== '';
};

export const buildLocale = (language?: string, country?: string) => {
  return language && country ? `${language}-${country}` : undefined;
};

export const getCountryItem = (country: string) => {
  return {
    value: country,
    label: i18next.t(`locale_picker.countries.${country}`),
  };
};

export const getLanguageItem = (language: string) => {
  return {
    value: language,
    label: i18next.t(`locale_picker.locales.${language}`),
  };
};
