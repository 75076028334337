/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

export const SESSION_EXPIRATION_TIMER_IN_MINUTES = 60;
export const SESSION_EXPIRATION_TIMER_IN_MS =
  SESSION_EXPIRATION_TIMER_IN_MINUTES * 60000;

export const LOCALE_COOKIE = 'vdz.locale.chosen_locale';
export const TERMS_ACCEPTANCE_DATE_COOKIE = 'vdz.terms.acceptance.date';
