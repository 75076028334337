/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

export const LOCALHOST = 'localhost';
export const IP4_REGEX = /^\d{1,3}(\.\d{1,3}){3}$/;
export const VDZ_DOMAIN_REGEX = /vdz-cdst-calculator\.com$/;

export const SIGN_UP_PAGE = '/sign-up';
export const LOGIN_PAGE = '/login';
export const DOC_CHECK_LOGIN_PAGE = `${LOGIN_PAGE}/:country/doccheck`;
export const TAKEDA_ID_LOGIN_PAGE = `${LOGIN_PAGE}/takeda-id`;

export const CALCULATOR_PAGE = '/';
export const CONTACT_US_PAGE = '/contact-us';
export const COOKIE_POLICY_PAGE = '/cookie-policy';
export const RESOURCES_PAGE = '/resources';
export const PRESCRIBING_INFORMATION_PAGE = '/pi';
