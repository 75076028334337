/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2023) Takeda. All Rights Reserved
 */

import React, { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { getCountryDatacenterUrl } from '../../../domain/global-config.domain';
import { VdzAnchor } from '../../shared/components/anchor.component';
import { ButtonType, VdzButton } from '../../shared/components/button.component';
import { VdzCheckbox } from '../../shared/components/checkbox.component';
import { VdzHTML } from '../../shared/components/html.component';
import { useGlobalConfig } from '../../shared/hooks/use-global-config/use-global-config.hook';

type Props = {
  country: string;
  language: string;
  hcp?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

export const VdzLocaleConfirmation: FC<Props> = ({
  country,
  language,
  hcp = false,
  onCancel,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const [termsChecked, setTermsChecked] = useState(false);
  const [datacenterUrl, setDatacenterUrl] = useState<string>();
  const [hcpChecked, setHcpChecked] = useState(hcp);
  const { globalConfig } = useGlobalConfig();

  useEffect(() => {
    setDatacenterUrl(
      country
        ? getCountryDatacenterUrl(country, globalConfig.countryConfiguration)
        : undefined
    );
  }, [country, globalConfig]);

  const translationProps = {
    country: t(`locale_picker.countries.${country}`),
    language: t(`locale_picker.locales.${language}`),
  };

  return (
    <div className="fixed w-full h-full top-0 z-40 text-center backdrop-filter backdrop-blur backdrop-brightness-50">
      <div className="m-5 lg:mx-64 lg:my-16 p-6 sm:p-8 bg-white text-black border rounded-3xl">
        <div className="font-semibold mb-4 text-xl">
          {t('locale_picker.confirmation.title', translationProps)}
        </div>
        <VdzHTML
          className="mb-4 md:leading-8"
          html={t('locale_picker.confirmation.text', translationProps)}
        />
        <div className="flex flex-row justify-center mb-8">
          <div className="flex flex-col items-start gap-4">
            <VdzCheckbox
              checked={termsChecked}
              onChange={(event) => setTermsChecked(event.target.checked)}
            >
              <Trans
                i18nKey="terms_acceptance.confirmation.body"
                components={{
                  anchor: (
                    <VdzAnchor
                      href={datacenterUrl + t('footer.terms_of_use.href') || ''}
                    />
                  ),
                }}
                shouldUnescape={true}
                values={{ address: t('footer.terms_of_use.title') }}
              ></Trans>
            </VdzCheckbox>
            <VdzCheckbox
              checked={hcpChecked}
              onChange={(event) => setHcpChecked(event.target.checked)}
              text={`${t('locale_picker.confirmation.checkbox_hcp', {
                country: t(`locale_picker.countries.${country}`),
              })}`}
            />
          </div>
        </div>

        <div className="flex flex-row justify-between">
          <VdzButton onClick={onCancel} buttonType={ButtonType.Tertiary}>
            {t('locale_picker.confirmation.cancel')}
          </VdzButton>

          <VdzButton
            disabled={!termsChecked || !hcpChecked}
            onClick={onConfirm}
          >
            {t('locale_picker.confirmation.confirm')}
          </VdzButton>
        </div>
      </div>
    </div>
  );
};
