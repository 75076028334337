/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

import { useLocation } from 'react-router-dom';

export function useParams() {
  const params = new URLSearchParams(useLocation().search);

  const getStringParam = (name: string) => {
    return params.get(name);
  };

  const getBooleanParam = (name: string) => {
    return params.get(name)?.toLowerCase() === 'true';
  };

  return { params, getStringParam, getBooleanParam };
}
