/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2023) Takeda. All Rights Reserved
 */

import './App.scss';

import React, { FC, Suspense, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { OktaAuth } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';

import {
  getAuthRequiredNavigateTo, getOidcConfig,
} from './domain/authentication/authentication.domain';
import { LoginType } from './domain/authentication/authentication.types';
import { getBaseUrl, isAppSelfHosted, isSafari } from './domain/browser.domain';
import { getCountryConfiguration, getCountryDatacenterUrl } from './domain/global-config.domain';
import { buildLocale } from './domain/i18n/i18n.domain';
import { setTrackedCustomUrl, setTrackedDocumentTitle } from './domain/tracking/tracking.domain';
import Authenticated from './modules/authentication/login/authenticated.component';
import { VdzDemoBanner } from './modules/banners/demo-banner/demo-banner.component';
import {
  VdzNotificationBanner,
} from './modules/banners/notification-banner/notification-banner.component';
import { COUNTRY_UNAVAILABLE } from './modules/error-modal/components/error.constants';
import { VdzHeader } from './modules/header/components/header.component';
import { VdzLocalePicker } from './modules/locale-picker/components/locale-picker.component';
import {
  CALCULATOR_PAGE, CONTACT_US_PAGE, COOKIE_POLICY_PAGE, DOC_CHECK_LOGIN_PAGE, LOGIN_PAGE,
  PRESCRIBING_INFORMATION_PAGE, RESOURCES_PAGE, SIGN_UP_PAGE, TAKEDA_ID_LOGIN_PAGE,
} from './modules/navigation/navigation.constants';
import { VdzSpinner } from './modules/shared/components/spinner.component';
import {
  useDocumentTitle,
} from './modules/shared/hooks/use-document-title/use-document-title.hook';
import { useEnvConfig } from './modules/shared/hooks/use-env-config/use-env-config.hook';
import { useGlobalConfig } from './modules/shared/hooks/use-global-config/use-global-config.hook';
import { useLocale } from './modules/shared/hooks/use-locale/use-locale.hook';
import { useParams } from './modules/shared/hooks/use-params/user-params.hook';
import {
  VdzTermsUpdatedConfirmation,
} from './modules/terms/components/terms-updated-confirmation/terms-updated-confirmation.component';
import { CalculatorPage } from './pages/calculator.page';
import { ContactUsAboutPage } from './pages/contact-us-about.page';
import { CookiePolicyPage } from './pages/cookie-policy.page';
import { LoginSelectionPage } from './pages/login-selection/login-selection.page';
import { LoginPage } from './pages/login.page';
import { PrescribingInformationPage } from './pages/prescribing-information.page';
import { ResourcesPage } from './pages/resources.page';
import { SignUpPage } from './pages/signup.page';
import { getEnvConfig } from './services/env-config/env-config.service';
import { getGlobalConfigData } from './services/global-config/global-config.service';
import { baseline } from './services/i18n/i18n.constants';
import { i18nService } from './services/i18n/i18n.service';

i18nService.init();

const App = () => {
  const navigate = useNavigate();
  const timeout = useRef<NodeJS.Timeout>();
  const oktaAuth = useRef<OktaAuth>();
  const { params, getStringParam, getBooleanParam } = useParams();
  const { pathname } = useLocation();
  const { globalConfig, setGlobalConfig } = useGlobalConfig();
  const { setEnvConfig } = useEnvConfig();
  const { country, language, locale, setLocale, clearLocale } = useLocale();
  const { i18n } = useTranslation();
  const [bypassLogin, setBypassLogin] = useState(false);

  const hasLocale = !!locale;

  useDocumentTitle('header.title');

  useEffect(() => {
    if (isSafari()) {
      document.getElementsByTagName('html')[0].classList.add('safari');
    }

    processOneTrust();

    if (hasLocale) {
      document.documentElement.lang = language;
      window.OneTrust?.changeLanguage();
    }

    const loadConfigData = async () => {
      const globalConfig = await getGlobalConfigData();
      setGlobalConfig(globalConfig);

      const envConfig = await getEnvConfig();
      setEnvConfig(envConfig);

      oktaAuth.current = new OktaAuth(getOidcConfig(envConfig));

      const hasParams = !!params.toString();
      if (hasParams) {
        const country = getStringParam('country')?.toUpperCase();
        const language = getStringParam('language')?.toLowerCase();
        const skipSelection = getBooleanParam('skipSelection');
        const isHCP = getBooleanParam('isHCP');
        const loggedIn = getBooleanParam('loggedIn');

        const countryConfig = getCountryConfiguration(
          country,
          globalConfig.countryConfiguration
        );

        const locale = buildLocale(language, country);
        if (locale) {
          i18nService.changeLocale(locale);
          if (skipSelection) setLocale(locale);
        }

        if (loggedIn && countryConfig?.canBypassLogin) {
          setBypassLogin(loggedIn);
        }

        if (skipSelection && loggedIn) {
          navigate(CALCULATOR_PAGE);
        } else {
          navigate(LOGIN_PAGE, {
            state: {
              country,
              language,
              isHCP,
              loggedIn,
              skipSelection,
            },
          });
        }
      } else if (!hasLocale && pathname !== LOGIN_PAGE) {
        navigate(LOGIN_PAGE);
      }
    };

    loadConfigData();
  }, []);

  useEffect(() => {
    const baseUrl = getBaseUrl();
    const datacenterUrl = country
      ? getCountryDatacenterUrl(country, globalConfig.countryConfiguration)
      : undefined;
    if (datacenterUrl && !baseUrl.startsWith(datacenterUrl)) {
      const pathname = window.location.pathname;
      const redirectUrl = `${datacenterUrl}${pathname}`;
      console.log(`Redirecting to :${redirectUrl}`);
      window.location.replace(redirectUrl);
    }

    if (country && globalConfig.countryConfiguration) {
      const countryConfig = getCountryConfiguration(
        country,
        globalConfig.countryConfiguration
      );
      if (!countryConfig) {
        i18nService.changeLocale(baseline);
        clearLocale();
        navigate(LOGIN_PAGE, { state: { error: COUNTRY_UNAVAILABLE } });
      }
    }
  }, [country, globalConfig]);

  useEffect(() => {
    if (locale && i18n.language !== locale) {
      i18nService.changeLocale(locale);
    }
  }, [locale, i18n.language]);

  useEffect(() => {
    setTrackedCustomUrl();
    setTrackedDocumentTitle();
  }, [window.location.hash]);

  const processOneTrust = () => {
    let oneTrustInterval: NodeJS.Timer | undefined = undefined;

    const hookOneTrust = () => {
      const oneTrustWrapper = document.querySelector('#onetrust-consent-sdk');
      if (oneTrustWrapper) {
        // show OneTrust when the site is self hosted (as opposed to running withing an iframe)
        if (isAppSelfHosted()) oneTrustWrapper.classList.add('visible');

        clearInterval(oneTrustInterval);
      }
    };

    // start an interval as fallback, but run immediately already
    oneTrustInterval = setInterval(hookOneTrust, 250);
    hookOneTrust();
  };

  const restoreOriginalUri = async (_oktaAuth: OktaAuth) => {
    navigate(window.location.origin, {
      replace: true,
    });
  };

  const customAuthHandler = () => {
    clearTimeout(timeout.current);

    navigate(getAuthRequiredNavigateTo(oktaAuth.current));
  };

  const AuthenticatedComponent: FC<{ success: JSX.Element }> = ({
    success,
  }) => (
    <>
      {locale && <VdzTermsUpdatedConfirmation />}

      {bypassLogin ? (
        success
      ) : (
        <Authenticated success={success} loading={<VdzSpinner />} />
      )}
    </>
  );

  return globalConfig && oktaAuth.current ? (
    <Security
      oktaAuth={oktaAuth.current}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      <div dir={i18n.dir()} className="app relative print:min-h-[100%]">
        <Suspense fallback={<VdzSpinner />}>
          {!hasLocale && <VdzLocalePicker />}
          <VdzNotificationBanner />
          <VdzDemoBanner />
          <VdzHeader />
          <Routes>
            <Route
              path={CALCULATOR_PAGE}
              element={<AuthenticatedComponent success={<CalculatorPage />} />}
            />
            <Route
              path={RESOURCES_PAGE}
              element={<AuthenticatedComponent success={<ResourcesPage />} />}
            />
            <Route
              path={CONTACT_US_PAGE}
              element={
                <AuthenticatedComponent success={<ContactUsAboutPage />} />
              }
            />
            <Route
              path={COOKIE_POLICY_PAGE}
              element={
                <AuthenticatedComponent success={<CookiePolicyPage />} />
              }
            />
            <Route
              path={PRESCRIBING_INFORMATION_PAGE}
              element={
                <AuthenticatedComponent
                  success={<PrescribingInformationPage />}
                />
              }
            />
            <Route path={SIGN_UP_PAGE} element={<SignUpPage />} />
            <Route path={LOGIN_PAGE} element={<LoginSelectionPage />} />
            <Route
              path={TAKEDA_ID_LOGIN_PAGE}
              element={<LoginPage type={LoginType.TakedaId} />}
            />
            <Route
              path={DOC_CHECK_LOGIN_PAGE}
              element={<LoginPage type={LoginType.DocCheck} />}
            />
          </Routes>
        </Suspense>
      </div>
    </Security>
  ) : (
    <Suspense />
  );
};

export default App;
