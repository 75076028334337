/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2023) Takeda. All Rights Reserved
 */

export const MESSAGE_NAMESPACE = 'vdz_Msgs';
export const baseline = 'en';
export const baselineLanguage = 'PR';
export const baselineLocale = `${baseline}-${baselineLanguage}`;
