/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Select, {
  components, DropdownIndicatorProps, MenuListProps, StylesConfig,
} from 'react-select';

import { getCountryFromLocale, getLanguageFromLocale } from '../../../domain/global-config.domain';
import { getCountryItem, getLanguageItem } from '../../../domain/i18n/i18n.domain';
import { CountriesDataType } from '../../../domain/locale-data.types';
import { getGlobalConfigData } from '../../../services/global-config/global-config.service';
import { COUNTRY_UNAVAILABLE } from '../../error-modal/components/error.constants';
import { SelectOption } from '../../form/form.types';
import { IconImage } from '../../images/images.constants';
import { getImageUrl } from '../../images/images.domain';
import { VdzModal } from '../../shared/components/modal.component';
import { useGlobalConfig } from '../../shared/hooks/use-global-config/use-global-config.hook';
import { useSession } from '../../shared/hooks/use-session/use-session.hook';

type SelectorProps = {
  data: CountriesDataType;
  handleChange: (country: string, locale: string) => void;
  locale?: string;
};

export const VdzLocaleSelector: FC<SelectorProps> = ({
  data,
  handleChange,
  locale,
}) => {
  const location = useLocation();
  const { removeExpirationDate, removeTimestamp } = useSession();
  const { setGlobalConfig } = useGlobalConfig();
  const { t } = useTranslation();

  const [error, setError] = useState();

  const [countryOptions, setCountryOptions] =
    useState<SelectOption<string>[]>();
  const [languageOptions, setLanguageOptions] =
    useState<SelectOption<string>[]>();

  const [selectedCountryOption, setSelectedCountryOption] =
    useState<SelectOption<string> | null>();
  const [selectedLanguageOption, setSelectedLanguageOption] =
    useState<SelectOption<string> | null>();

  useEffect(() => {
    const hasParams = !!location.state;
    if (hasParams) {
      const { country, language } = location.state;

      if (country) setSelectedCountryOption(getCountryItem(country));
      if (country && language) {
        setSelectedLanguageOption(getLanguageItem(language));
        handleChange(country, language);
      }
    }
  }, []);

  useEffect(() => {
    setCountryOptions(
      Object.keys(data)
        .map(getCountryItem)
        .sort((a, b) => a.label.localeCompare(b.label))
    );
  }, [data]);

  useEffect(() => {
    setLanguageOptions(
      selectedCountryOption
        ? data[selectedCountryOption.value].locales.map(getLanguageItem)
        : undefined
    );
  }, [selectedCountryOption]);

  useEffect(() => {
    if (locale) {
      const country = getCountryFromLocale(locale);
      const language = getLanguageFromLocale(locale);

      setSelectedCountryOption(country ? getCountryItem(country) : null);
      setSelectedLanguageOption(language ? getLanguageItem(language) : null);
    } else {
      setSelectedLanguageOption(null);
    }
  }, [locale]);

  useEffect(() => {
    // clear session data when kicked out to the selector
    removeTimestamp();
    removeExpirationDate();

    const { error } = location.state || {};
    if (error) {
      setError(error);

      if (error === COUNTRY_UNAVAILABLE) {
        // if backend says country is unavailable, we fetch the config again to
        // update the countries removed from the list
        loadConfigData();
      }
    }
  }, [location]);

  const loadConfigData = async () => {
    setGlobalConfig(await getGlobalConfigData());
  };

  const handleSelectedCountry = (country: SelectOption<string> | null) => {
    setSelectedCountryOption(country);
    setSelectedLanguageOption(null);
  };

  const handleSelectedLanguage = (language: SelectOption<string> | null) => {
    if (!language) return;

    setSelectedLanguageOption(language);
    handleChange(
      (selectedCountryOption?.value as string) || '',
      (language?.value as string) || ''
    );
  };

  const CaretDownIcon = () => {
    return (
      <img
        src={getImageUrl(IconImage.CountryPickerDownArrow)}
        alt="country-picker-down-arrow"
      />
    );
  };

  const CaretUpIcon = () => {
    return (
      <img
        src={getImageUrl(IconImage.CountryPickerUpArrow)}
        alt="country-picker-up-arrow"
      />
    );
  };

  const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => {
    const { menuIsOpen } = props.selectProps;

    return (
      <components.DropdownIndicator {...props}>
        {menuIsOpen ? <CaretUpIcon /> : <CaretDownIcon />}
      </components.DropdownIndicator>
    );
  };

  const MenuList = (
    title: string,
    props: MenuListProps<SelectOption<string>>
  ) => {
    return (
      <components.MenuList {...props}>
        <div className="bg-[#f7f7f7] text-black font-bold text-sm p-2">{`${title}`}</div>
        <div className="text-sm">{props.children}</div>
      </components.MenuList>
    );
  };

  const dropDownStyles: StylesConfig = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#f7f7f7' : 'inherit',
      borderBottom: '1px solid #B7B7B7',
      color: 'black',
      '&:hover': {
        backgroundColor: '#f7f7f7',
      },
    }),
  };

  return (
    <div className="m-auto text-lg leading-relaxed text-slate-500 flex justify-center">
      <VdzModal
        buttonText="error_modal.close"
        containerClassName="text-center"
        text="error_modal.country_unavailable"
        title="error_modal.title"
        isVisible={error === COUNTRY_UNAVAILABLE}
        onClose={() => setError(undefined)}
      ></VdzModal>

      {data && (
        <div>
          <div className="mx-4 flex items-center">
            <img
              className="md:me-2 h-6 w-6"
              src={getImageUrl(IconImage.CountryPickerGlobe)}
              alt="country-picker-globe"
            ></img>
            <label htmlFor="country" />
            <Select
              className="p-2 w-52 md:w-96 lg:w-[512px] rounded-md"
              classNames={{
                control: () => 'text-lg',
              }}
              name="country"
              id="country"
              placeholder={t('locale_picker.select_country')}
              onChange={(e: unknown) =>
                handleSelectedCountry((e as SelectOption<string>) || null)
              }
              value={selectedCountryOption}
              options={countryOptions}
              components={{
                DropdownIndicator,
                IndicatorSeparator: () => null,
                MenuList: (props) =>
                  MenuList(
                    t('locale_picker.select_country'),
                    props as MenuListProps<SelectOption<string>>
                  ),
              }}
              styles={dropDownStyles}
            />
          </div>

          <div className="mx-4 mb-8 flex items-center">
            <img
              className="md:me-2 h-6 w-6"
              src={getImageUrl(IconImage.CountryPickerLanguage)}
              alt="country-picker-language"
            ></img>
            <label htmlFor="language" />
            <Select
              className="p-2 w-52 md:w-96 lg:w-[512px] rounded-md"
              classNames={{
                control: () => 'text-lg',
              }}
              name="language"
              id="language"
              placeholder={t('locale_picker.select_language')}
              isDisabled={!selectedCountryOption}
              onChange={(e: unknown) =>
                handleSelectedLanguage((e as SelectOption<string>) || null)
              }
              value={selectedLanguageOption}
              options={languageOptions}
              components={{
                DropdownIndicator,
                IndicatorSeparator: () => null,
                MenuList: (props) =>
                  MenuList(
                    t('locale_picker.select_language'),
                    props as MenuListProps<SelectOption<string>>
                  ),
              }}
              styles={dropDownStyles}
            />
          </div>
        </div>
      )}
    </div>
  );
};
